import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Desc, DescLabel } from "../../../Global/Typography";
import BannerNew from "../../BannerNew";
import "./index.scss";

import { Accordion, Card } from "react-bootstrap";
import BlogData from "../../../../db/BlogEngraceVitsaTrue3BHK";
import ShareButton from "../../Share/ShareButton";

window.scrollToElement = function (id) {
    const el = document.querySelector(id);
    if (el) {
        window.scrollTo({
            behavior: "smooth",
            top: 200,
        });
    }
};

export default function Banner({ inView }) {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0"
                />
                <title>Why is Engrace Vista the True 3 BHK?</title>
                <meta
                    name="Keywords"
                    content="" //TODO
                />
                <meta
                    name="description"
                    content="Discover Engrace Vista: Homes with spacious layouts and modern amenities designed to redefine your lifestyle. 
"
                ></meta>
                <link
                    rel="canonical"
                    href="https://modernspaaces.com/blogs/why-is-engrace-vista-the-true-3-bhk"
                />
            </Helmet>
            <section
                className={`section why-is-engrace-vista-the-true-3-bhk  ${
                    inView ? "inView" : "outView"
                }`}
            >
                <BannerNew
                    image={BlogData.image}
                    title={BlogData.title}
                    publishedOn={BlogData.publishedOn}
                    time={BlogData.time}
                    alt={BlogData.imageAlt}
                    imageFit={"fill"}
                />
                <Container className="blog-detail-container">
                    <Row className="justify-content-around">
                        <Col xs={12} md={11} lg={9} className="pb-4 pr-md-5">
                            <div className="blog-header margin">
                                {BlogData.description1 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description1}
                                    />
                                )}
                                {BlogData.title2 && (
                                    <p className="-descBlack font-benton-bold margin mb-3">
                                        {BlogData.title2}
                                    </p>
                                )}
                                {BlogData.description21 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description21}
                                    />
                                )}
                                {BlogData.title3 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title3}
                                    </p>
                                )}
                                {BlogData.description31 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description31}
                                    />
                                )}
                                {BlogData.title4 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title4}
                                    </p>
                                )}
                                <div className="imagepadding">
                                    <img
                                        src={BlogData.image41}
                                        alt={BlogData.imageAlt41}
                                        class="image margin"
                                    />
                                </div>
                                {BlogData.description42 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description42}
                                    />
                                )}
                                {BlogData.list43 && (
                                    <ul className="blog-list-circle  header">
                                        {BlogData.list43.map((i0, idx1) => {
                                            return (
                                                <li key={idx1}>
                                                    <DescLabel
                                                        classList="my-1 font-benton-book header "
                                                        text={i0.description}
                                                        label={i0.label}
                                                    />
                                                    {i0.list && (
                                                        <ul className="header pl-3">
                                                            {i0.list.map(
                                                                (i1, idx1) => {
                                                                    return (
                                                                        <li
                                                                            key={
                                                                                idx1
                                                                            }
                                                                        >
                                                                            <DescLabel
                                                                                classList="my-1 font-benton-book header "
                                                                                text={
                                                                                    i1.description
                                                                                }
                                                                                label={
                                                                                    i1.label
                                                                                }
                                                                            />
                                                                        </li>
                                                                    );
                                                                }
                                                            )}
                                                        </ul>
                                                    )}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                )}
                                <div className="comparison-table-wrapper py-4">
                                    {BlogData.tableSectionHeading44 && (
                                        <p className="-descBlack font-benton-bold  margin text-center">
                                            {BlogData.tableSectionHeading44}
                                        </p>
                                    )}
                                    <table className="comparison-table">
                                        <thead>
                                            <tr>
                                                {BlogData.table44header.map(
                                                    (headerItem) => {
                                                        return (
                                                            <th>
                                                                <p className="font-benton-book mb-0">
                                                                    {headerItem}
                                                                </p>
                                                            </th>
                                                        );
                                                    }
                                                )}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {BlogData.table44.map(
                                                (rowItem, idx) => {
                                                    return (
                                                        <tr key={idx}>
                                                            <>
                                                                <td>
                                                                    <Desc
                                                                        classList="my-1 font-benton-bold header "
                                                                        text={
                                                                            rowItem.col1
                                                                        }
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Desc
                                                                        classList="font-benton-book header mb-0"
                                                                        text={
                                                                            rowItem.col2
                                                                        }
                                                                    />
                                                                </td>
                                                            </>
                                                        </tr>
                                                    );
                                                }
                                            )}
                                        </tbody>
                                    </table>
                                </div>

                                {BlogData.list45 && (
                                    <ul className="blog-list-circle  header">
                                        {BlogData.list45.map((i0, idx1) => {
                                            return (
                                                <li key={idx1}>
                                                    <DescLabel
                                                        classList="my-1 font-benton-book header "
                                                        text={i0.description}
                                                        label={i0.label}
                                                    />
                                                </li>
                                            );
                                        })}
                                    </ul>
                                )}
                                {BlogData.title5 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title5}
                                    </p>
                                )}
                                <div className="imagepadding">
                                    <img
                                        src={BlogData.image51}
                                        alt={BlogData.imageAlt51}
                                        class="image margin"
                                    />
                                </div>
                                {BlogData.description52 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description52}
                                    />
                                )}
                            </div>
                        </Col>
                    </Row>
                    <Row className="simpleShadow my-3 py-3 justify-content-around">
                        <Col
                            xs={12}
                            md={11}
                            lg={9}
                            className="pb-4 pr-md-5 p-2"
                        >
                            <Row>
                                <div
                                    className="blog-content px-3"
                                    style={{
                                        width: "-webkit-fill-available",
                                    }}
                                >
                                    {BlogData.Faq && (
                                        <p className="-descBlack font-benton-bold  margin header2">
                                            {BlogData.Faq}
                                        </p>
                                    )}
                                    {BlogData.faqs &&
                                        BlogData.faqs.map((faq, faqIdx) => (
                                            <Accordion
                                                key={faqIdx}
                                                defaultActiveKey={faqIdx.toString()}
                                            >
                                                <Card
                                                    key={faqIdx}
                                                    className="my-2 p-2 font-benton-bold"
                                                    style={{
                                                        width: "fit",
                                                        border: "none",
                                                        backgroundColor:
                                                            "rgba(0, 0, 0, 0.02)",
                                                        borderRadius: "0.5rem",
                                                    }}
                                                >
                                                    <Accordion.Toggle
                                                        as={Card.Header}
                                                        eventKey={`${faqIdx}`}
                                                        className="font-benton-bold faq"
                                                        style={{
                                                            border: "none",
                                                            background: "none",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                                justifyContent:
                                                                    "space-between",
                                                                cursor: "pointer",
                                                                borderBottom:
                                                                    "none",
                                                            }}
                                                        >
                                                            {faq.label}
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="20"
                                                                height="21"
                                                                viewBox="0 0 20 21"
                                                                fill="none"
                                                            >
                                                                <path
                                                                    d="M16.5984 7.96094L11.1651 13.3943C10.5234 14.0359 9.47344 14.0359 8.83177 13.3943L3.39844 7.96094"
                                                                    stroke="black"
                                                                    strokeOpacity="0.32"
                                                                    strokeWidth="2"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                            </svg>
                                                        </div>
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse
                                                        eventKey={`${faqIdx}`}
                                                    >
                                                        <Card.Body className="font-benton-book header p-3">
                                                            {faq.description[0]}
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </Accordion>
                                        ))}
                                </div>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="simpleShadow my-3 py-3 justify-content-around">
                        <Col
                            xs={12}
                            md={11}
                            lg={9}
                            className="pb-4 pr-md-5 p-2"
                        >
                            <div
                                className="shareLinkBlock1 d-flex flex-row p-4 "
                                style={{
                                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                                    gap: "1rem",
                                    borderRadius: "1rem",
                                }}
                            >
                                <ShareButton shareUrl="https://modernspaaces.com/blogs/why-is-engrace-vista-the-true-3-bhk" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}
