const banner = "/images/Blogs/BlogEngraceVistaTrue3BHK.png";
const true3Bhk = "/images/Blogs/TheTrue3BHK.png";
const engraceVista = "/images/Blogs/EngraceVista3BHK.png";

const data = {
    slug: "why-is-engrace-vista-the-true-3-bhk",
    image: banner,
    imageAlt: "Why is Engrace Vista the True 3 BHK?",
    title: "Why is Engrace Vista the True 3 BHK?",
    time: "4 min read",
    publishedOn: "October, 2024",
    description1:
        "Tired of empty promises about spacious, oversized homes? Have you ever dreamt of a 3 BHK that truly lives up to the name? One that offers more than just the basic layout and something with the right amount of space and flexibility that perfectly fits your lifestyle. Then don’t scroll away because Engrace Vista is here and it is everything that you would expect from a 3 BHK.",

    title2: (
        <div id="why-are-3-bhk-trending" className="scroll-top">
            Why are 3 BHK’s Trending?
        </div>
    ),
    description21: (
        <>
            The demand for 3 BHK homes in Bangalore is on the rise, and for good
            reason. As our need for space increases, more and more families are
            seeking that extra room to accommodate their growing family or to
            create a dedicated space for work, hobbies, or guests.
            <br></br>
            However, it's essential to understand that not all 3 BHK homes are
            created equal. Many of the so-called 3 BHK apartments available in
            the market today are actually just 2.5BHKs in disguise. These homes
            typically range from 1100 to 1700 square feet, offering limited
            space that doesn't truly fulfil the needs of a full-fledged 3BHK
            home.
        </>
    ),
    title3: (
        <div id="what-is-a-true-3-bhk?" className="scroll-top">
            What is a True 3 BHK?
        </div>
    ),
    description31:
        "Imagine a 3 BHK home designed for ultimate comfort and privacy. Spacious bedroom with balconies, perfect for private moments. The expansive living area is ideal for some quality family time. While the well-equipped kitchen includes a dedicated utility space for seamless organisation. Built with premium fixtures and high-quality materials, this home is a secure investment that transcends generations. Here, memories are made, and legacies are passed on.",
    title4: (
        <div id="engrace-vista-redefines-3-bhk-living" className="scroll-top">
            Engrace Vista redefines 3 BHK Living
        </div>
    ),
    image41: true3Bhk,
    imageAlt41: "The True 3 BHK",
    description42:
        "Forget the frustration of settling for less. At Engrace Vista, we understand your desire for a spacious 3 BHK where you won't find compromises; you'll find the perfect home that is RERA* certified too.",
    list43: [
        {
            label: "Size",
            description:
                "Engrace Vista, Sarjapur's exclusive 3 BHK community, offers expansive living spaces ranging from 2,116 to 2,200 sq ft and is developed on an 8-acre land parcel with only a limited 392 units.",
        },
        {
            label: "Bedrooms",
            description: (
                <>
                    Our expansive 2,200 sq. ft. unit features a massive master
                    bedroom measuring 19.5 ft. x 14.9 ft. (approximately 6.0 x
                    4.5 metres), complete with a walk-in wardrobe, attached
                    bathroom, and a private balcony where you can enjoy your
                    morning coffee or unwind in the evening.<br></br> The other
                    two bedrooms are also impressively spacious, allowing you to
                    fit most furniture comfortably without compromising on style
                    or functionality. Each room is thoughtfully designed to
                    ensure that every piece of furniture has its place, giving
                    you the freedom to arrange your space exactly how you like
                    it.<br></br> Forget cramped rooms and limited space! Our
                    generously proportioned bedrooms offer the ultimate
                    flexibility and comfort. Whether you need a guest room, a
                    home office, or a personal retreat, these rooms can adapt to
                    your needs effortlessly.
                </>
            ),
        },
        {
            label: "Spacious",
            description:
                "Engrace Vista by Modern Spaaces has been designed with extra spacious rooms, ensuring comfort and convenience.",
            list: [
                {
                    label: "a) Open Layout",
                    description:
                        "Minus visual barriers, your home feels larger and more expansive. With minimal walls between the kitchen, living, and dining areas, natural light has the freedom to flow freely throughout the space.",
                },
                {
                    label: "b) Cross-ventilation",
                    description: (
                        <>
                            Large, strategically placed 8-feet windows on
                            opposite sides promote exceptional
                            cross-ventilation, which means your reliance on
                            artificial lighting is reduced to a great extent.
                            <br></br>
                            Additionally, effective ventilation prevents mould
                            growth, residue, and air pollutants which can be
                            harmful to your health.
                        </>
                    ),
                },
            ],
        },
    ],
    tableSectionHeading44: "Project at Glance",
    table44header: ["Details", "Information"],
    table44: [
        {
            col1: "Size",
            col2: "2116 to 2200 sq.ft.",
        },
        {
            col1: "Construction",
            col2: "Aluform Technology",
        },
        {
            col1: "Location",
            col2: "Off Sarjapur Road",
        },
        {
            col1: "Legal Compliance",
            col2: "BMRDA approved, RERA",
        },
        {
            col1: "Possession",
            col2: "June 2027",
        },
        {
            col1: "Structure",
            col2: "B+G+14",
        },
    ],
    list45: [
        {
            label: "No common walls",
            description: (
                <>
                    Privacy in apartment complexes is one of the major concerns
                    for most homebuyers, as nobody wants intrusive neighbours
                    keeping an eye on their every move. At Modern Spaaces, we
                    understand the value and importance of privacy, and we've
                    designed our homes with this in mind.<br></br> Our unique
                    design ensures that each unit doesn’t share a common wall
                    with the neighbouring units. This innovative approach means
                    you can live life on your own terms, enjoying unparalleled
                    privacy and tranquillity.<br></br> Imagine the freedom to
                    host a lively family movie night without worrying about
                    disturbing the neighbours. Your children can study for their
                    exams in peace, free from the distractions of noise from
                    adjacent apartments. Whether you’re working from home,
                    practising a musical instrument, or simply enjoying a quiet
                    moment of reflection, you can do so without unwanted noise
                    or disruptions.
                </>
            ),
        },
        {
            label: "4 Units per Floor",
            description: (
                <>
                    All units in this community are corner units which means
                    more expansive views compared to regular units. With windows
                    on two or more sides, your home is filled with natural light
                    and boasts breathtaking views of the surrounding landscape.
                    Whether it's a captivating sunrise or a refreshing rain
                    shower, these expansive windows enhance the beauty and
                    enjoyment of your home.<br></br> The strategic placement of
                    corner units within the building makes them more desirable.
                    The increased visibility and prime location of these units
                    contribute to their higher market value. As a result, corner
                    units often offer better resale values in the future, making
                    them a wise investment for discerning homebuyers.
                </>
            ),
        },
        {
            label: "3 Wide Balconies",
            description: (
                <>
                    We believe your home should be an extension of your
                    lifestyle. That's why every 3 BHK apartment at Engrace Vista
                    boasts not just spacious interiors, but also three expansive
                    private balconies of 4.92 feet by 14.11 feet (1.5 mts by 4.3
                    mts) with a sunken planter.<br></br> Studies show that
                    exposure to sunlight and connection with nature enhance
                    mental well-being, offering a much-needed escape from the
                    bustling city. Create a cosy seating area for intimate
                    gatherings with friends or even create a kitchen garden or a
                    zen garden. The possibilities are endless!
                </>
            ),
        },
        {
            label: "Luxurious Amenities",
            description: (
                <>
                    Our fabulous clubhouse has a squash court, gym, board games,
                    foosball, table tennis, billiards, banquet hall, and guest
                    accommodation. A dedicated recreational zone consisting of
                    rejuvenating swimming pools for adults and kids, a serene
                    senior citizen lounge, children’s play area, tranquil Yoga
                    deck, vibrant outdoor party area, lush tropical garden. An
                    active zone that consists of a dedicated pet park,
                    multi-sports court and a scenic walking track for fitness
                    enthusiasts. <br></br>Each home at Engrace Vista features
                    engineered door frames and wooden shutters with a veneer
                    finish, complemented by high-quality hardware. UPVC sliding
                    windows come with provisions for mosquito mesh shutters,
                    ensuring comfort and convenience. Each apartment is
                    connected to a gas bank with a reticulated piped gas system,
                    monitored by individual gas metres. Dedicated parking spaces
                    and balcony access provide added value, while provisions for
                    plumbing lines, including points for gas and water, offer
                    flexibility.
                </>
            ),
        },
        {
            label: "Sustainability",
            description:
                "Engrace Vista is committed to sustainability. With features like STP (Sewage Water Treatment Plant)for treated water reuse, a water treatment plant for purified water supply, smart water metres, rainwater harvesting, EV charging points, solar power lighting, and water heaters on the top two floors. These features help reduce the project's environmental impact and promote sustainable living.",
        },
    ],

    title5: "Engrace Vista is the True 3 BHK",
    image51: engraceVista,
    imageAlt51: "Engrace Vista is the True 3 BHK",
    description52: (
        <>
            Modern Spaces is committed to delivering spacious homes and
            exceptional build quality. More than 1000+ families have found their
            forever spaces.{" "}
            <b className="font-benton-bold">Are you ready to join them?</b>
            <br></br> Discover unparalleled privacy and exclusivity at Engrace
            Vista, offering authentic 3 BHK apartments ranging from 2116 to 2200
            sq. ft. Each floor features only four corner units, ensuring
            complete separation with no common walls at an unbeatable value.
            With a total of just 392 units, Engrace Vista promises a low-density
            <a
                href="https://modernspaaces.com/blogs/benefits-of-living-in-an-exclusive-3-BHK-community"
                className="external-link"
                target="_blank"
                rel="noreferrer"
            >
                {" "}
                community
            </a>
            , providing the space, comfort, and exclusivity you deserve.
            <br></br>{" "}
            <b className="font-benton-bold">Want to own a True 3 BHK?</b> For
            more information, call us on 99 000 99 324 or visit{" "}
            <a
                href="https://modernspaaces.com"
                className="external-link"
                target="_blank"
                rel="noreferrer"
            >
                www.modernspaaces.com{" "}
            </a>
            – we're here to help make your dream home a reality.
            <br></br>
            <br></br> * <b className="font-benton-bold">Project RERA no</b>:
            PRM/KA/RERA/1251/308/PR/150223/005723
        </>
    ),
};

export default data;
