// import ImgMivanTech from "../../../images/Blogs/MivanTechnology.jpeg";
// import ImgReraInfuence from "../../../images/Blogs/reraInfluence.png";
// import ImgAdvOfVilla from "../../../images/Blogs/advOfVillas.png";
// import ImgFactorsAffectingRE from "../../../images/Blogs/FactorsImpactingReLocations.png";
// import EnergyEfficient from "../../../images/Blogs/EnergyEfficientBuilding.png";

const ImgMivanTech = "/images/Blogs/mivan-technology-banner.jpeg";
const ImgReraInfuence = "/images/Blogs/rera-act-influence-banner.png";
const ImgAdvOfVilla = "/images/Blogs/advantages-of-luxury-villas-banner.png";
const ImgFactorsAffectingRE =
    "/images/Blogs/impact-on-real-estate-location-banner.png";
const EnergyEfficient = "/images/Blogs/energy-efficient-building-banner.png";
const ImgExclusive3BHKCommunity =
    "/images/Blogs/benefits-of-living-in-an-exclusive-3-BHK-community.png";
const budget2024 = "/images/Blogs/budget-2024.jpg";
const readyMadeOrCustomImage = "/images/Blogs/ready-made-or-custom-villa.png";
const apartmentVsHouse = "/images/Blogs/BlogApartmentVsHouse.png";
const engraceVistaTrue3BHK = "/images/Blogs/BlogEngraceVistaTrue3BHK.png";

export const AllBlogs = [
    {
        id: "buying-apartment-vs-building-house",
        slug: "buying-apartment-vs-building-house",
        image: apartmentVsHouse,
        imgAlt: "Are you still over the fence between Buying an Apartment and Building a House?",
        label: "Real Estate",
        desc: "Explore the key differences between building an independent home and buying an apartment. This guide covers cost, time, customisation, and resale value to help you choose the best path to your dream home.",
        title: "Are you still over the fence between Buying an Apartment and Building a House?",
        date: "October, 2024",
        time: "7 min read",
        imageStyle: {
            objectFit: "cover",
            objectPosition: "left",
        },
    },
    {
        id: "why-is-engrace-vista-the-true-3-bhk",
        slug: "why-is-engrace-vista-the-true-3-bhk",
        image: engraceVistaTrue3BHK,
        imgAlt: "Why is Engrace Vista the True 3 BHK?",
        label: "Real Estate",
        desc: "Discover Engrace Vista: Homes with spacious layouts and modern amenities designed to redefine your lifestyle.",
        title: "Why is Engrace Vista the True 3 BHK?",
        date: "October, 2024",
        time: "4 min read",
    },
    {
        id: "readyMadeOrCustom",
        slug: "ready-made-villa-vs-custom-build",
        image: readyMadeOrCustomImage,
        imgAlt: "Ready-Made Villa or Custom Build: What’s Your Perfect Home Match?",
        label: "Real Estate",
        desc: "Discover the pros and cons of buying a villa versus building a standalone home. Explore factors like luxury, privacy, customisation, and budget to determine the best option for your lifestyle.",
        title: "Ready-Made Villa or Custom Build: What’s Your Perfect Home Match?",
        date: "September, 2024",
        time: "7 min read",
        imageStyle: {
            objectFit: "cover",
            objectPosition: "left",
        },
    },
    {
        id: "budget2024",
        slug: "budget-2024",
        image: budget2024,
        imgAlt: "Budget 2024 - Your Wallet's New BFF or Your Worst Enemy",
        label: "Real Estate",
        desc: "Discover how Budget 2024 reshapes India's real estate landscape. Understand the potential implications for property buyers, sellers, and investors.",
        title: "Budget 2024 : Your Wallet's New BFF or Your Worst Enemy",
        date: "September, 2024",
        time: "7 min read",
    },
    {
        id: "abc100",
        slug: "benefits-of-living-in-an-exclusive-3-BHK-community",
        title: "Benefits of living in an exclusive 3 BHK community",
        imgAlt: "Exclusive 3 BHK community",
        image: ImgExclusive3BHKCommunity,
        desc: "Discover Engrace Vista, an exclusive 3 BHK community off Sarjapur Road, Bangalore. Experience the best of urban living with spacious apartments, top-tier amenities, enhanced security, and a vibrant community atmosphere",
        date: "May,2024",
        time: "8 min read",
    },
    {
        id: "abc101",
        slug: "exploring-the-mivan-technology",
        image: ImgMivanTech,
        imgAlt: "Mivan Technology - Aluform Technology",
        label: "Real Estate",
        desc: "Exploring the technology of Mivan and its impact on construction. Learn more about the technology through a detailed analysis.",
        title: "Revolutionizing Construction: Exploring the Mivan Technology",
        date: "Jan, 2024",
        time: "5 min read",
    },
    {
        id: "abc102",
        slug: "rera-how-it-benefits-homeowners-and-developers",
        // image: "https://modernspaaces.com/BlogListImages/BlogTwo/Banner.webp",
        image: ImgReraInfuence,
        imgAlt: "RERA benifits of homeowner and developers ",
        label: "Real Estate",
        desc: "Discover how the RERA Act impacts home buyers and developers in India. Learn more about the basic principles and the impact it has upon home buyers and developers.",
        title: "RERA's Influence | How does It Benefit the Homeowners and Developers? ",
        date: "Jan, 2024",
        time: "4 min read",
    },
    {
        id: "abc104",
        slug: "choose-between-green-traditional-energy-efficient-buildings",
        // image: "https://modernspaaces.com/BlogListImages/BlogFour/Banner.webp",
        image: EnergyEfficient,
        imgAlt: "Energy Efficent Buildings",
        label: "Real Estate",
        desc: "Urbanization and the Changing Landscape The global wave of urbanization has profoundly reshaped landscapes, impacting natural resources and intensifying carbon emissions.",
        title: "Choosing Between Green, Traditional, and Energy-Efficient Buildings in Real Estate",
        date: "Jan, 2024",
        time: "5 min read",
    },
    {
        id: "abc104",
        slug: "advantage-of-villas-exploring-luxury-living",
        // image: "https://modernspaaces.com/BlogListImages/BlogFour/Banner.webp",
        image: ImgAdvOfVilla,
        imgAlt: "Advantages of Luxury Living Villas",
        label: "Real Estate",
        desc: "Are you feeling overwhelmed by all the choices for your new home? Unsure whether you should construct your own home or buy a ready-to-move-in Villa? Are you confused...",
        title: "Prime Advantage of Villas: Exploring Luxury Living",
        date: "Jan, 2024",
        time: "3 min read",
    },
    {
        id: "abc104",
        slug: "factors-impacting-real-estate-location",
        // image: "https://modernspaaces.com/BlogListImages/BlogFour/Banner.webp",
        image: ImgFactorsAffectingRE,
        imgAlt: "Factors Impacting Real Estate Location",
        label: "Real Estate",
        desc: "Explore the vital role of location in real estate investments. Learn how factors like amenities, economic trends, and growth potential shape a property's value.",
        title: "Factors Impacting Real Estate Location",
        date: "Jan, 2024",
        time: "3 min read",
    },

    // {
    //     id: "abc103",
    //     slug: "why-sarjapura-best-area-to-buy-house",
    //     // image: "https://modernspaaces.com/BlogListImages/BlogFour/Banner.webp",
    //     image: ImgTopReasonsSarjapura,
    //     label: "REAL ESTATE",
    //     desc: "For any real estate project, its amenities are the most crucial part, as that’s what the builders use to lure potential home buyers For any real estate project, its amenities are the most crucial part, as that’s what the builders use to lure…",
    //     title: "Top reasons why Sarjapur Road is the best area for buying a home",
    //     date: "Dec, 2023",
    //     time: "3 min read",
    // },
    // {
    //     slug: "things-to-consider-buying-your-first-home",
    //     title: "Everything you need to know before buying your first home",
    //     image: img2,
    //     label: "REAL ESTATE",
    //     // img: "https://modernspaaces.com/BlogListImages/BlogTwo/Banner.webp",
    //     desc: "After years of hard work and saving up, you have finally decided to buy your first house more...",
    //     date: "Dec, 2021",
    //     time: "3 min read",
    // },
    // {
    //     slug: "list-of-amenities-to-consider-buying-home",
    //     title: "A complete list of amenities you should look for before buying a",
    //     // img: "https://modernspaaces.com/BlogListImages/BlogOne/Banner.webp",
    //     image: img1,
    //     label: "REAL ESTATE",
    //     desc: "For any real estate project, its amenities are the most crucial part, as that’s what the builders use to lure potential..",
    //     date: "Dec, 2021",
    //     time: "2 min read",
    // },

    // {
    //     slug: "buying-your-house-in-neighborhood",
    //     title: "Top benefits of buying your dream home in a gated community",
    //     // img: "https://modernspaaces.com/BlogListImages/BlogThree/Banner.webp",
    //     label: "REAL ESTATE",
    //     image: img3,
    //     desc: "A housing property or a residential community with a name and geographical demarcation set apart with gates.",
    //     date: "Jan, 2021",
    //     time: "2 min read",
    // },

    // {
    //     id: "abc103",
    //     slug: "simple-guide-to-understanding-property-tax",
    //     // image: "https://modernspaaces.com/BlogListImages/BlogThree/Banner.webp",
    //     image: ImgPropertyTax,
    //     label: "Real Estate",
    //     desc: "Learn about various tax forms, their calculation methods, and their essential role in government services and its impact on homeowners.",
    //     title: "A Simple Guide to Understanding Property taxes.",
    //     date: "Dec,2021",
    //     time: "2 min read",
    // },
    // {
    //     id: "abc104",
    //     slug: "defference-between-a-khata-b-khata-and-its-impact-on-property",
    //     // image: "https://modernspaaces.com/BlogListImages/BlogFour/Banner.webp",
    //     image: ImgKhata,
    //     label: "Real Estate",
    //     desc: "Learn about A Khata and B Khata properties in Bangalore, their impact on real estate, the process of Khata transfer, and how property tax is calculated…",
    //     title: "Difference between A khata and B khata and its impact on Property",
    //     date: "Dec,2021",
    //     time: "2 min read",
    // },
    // {
    //     id: "abc104",
    //     slug: "the-evolution-of-smart-homes",
    //     // image: "https://modernspaaces.com/BlogListImages/BlogFour/Banner.webp",
    //     image: ImgSciFi,
    //     label: "Industry",
    //     desc: "Experience the future with smart homes! Discover how smart home technology enhances convenience, security, and energy efficiency.Lets find out more...",
    //     title: "From Sci-Fi Dreams to Everyday Reality: The Evolution...",
    //     date: "Dec,2021",
    //     time: "2 min read",
    // },
    // {
    //     id: "abc104",
    //     slug: "comparing-the-cost-building-vs-buying-homes",
    //     // image: "https://modernspaaces.com/BlogListImages/BlogFour/Banner.webp",
    //     image: ImgCostComparison,
    //     label: "Industry",
    //     desc: "Comparing the pros and cons of buying a home vs buying an apartment. This blog aims to guide you in making an informed decision.",
    //     title: "Comparing the Cost: Building Vs Buying Homes.",
    //     date: "Dec,2021",
    //     time: "2 min read",
    // },

    // {
    //     id: "abc104",
    //     slug: "urbanisation-vs-changing-lanscape",
    //     // image: "https://modernspaaces.com/BlogListImages/BlogFour/Banner.webp",
    //     image: ImgUrbanisation,
    //     label: "Industry",
    //     desc: "Urbanization and the Changing Landscape.The global wave of urbanization has profoundly reshaped landscapes, impacting natural resources and intensifying carbon...",
    //     title: "Choosing Between Green, Traditional, and Energy-Efficient...",
    //     date: "Dec,2021",
    //     time: "2 min read",
    // },
    // {
    //     id: "abc104",
    //     slug: "certificate-of-occupancy-simple-guide-to-what-it-means",
    //     // image: "https://modernspaaces.com/BlogListImages/BlogFour/Banner.webp",
    //     image: ImgCertificateOC,
    //     label: "Industry",
    //     desc: "Understand the essential role of the Occupancy Certificate (OC) in your home purchase journey and how it safeguards your consumer rights.",
    //     title: "Certificate of Occupancy: A Simple Guide to What It Means.",
    //     date: "Dec,2021",
    //     time: "2 min read",
    // },

    // {
    //     id: "abc104",
    //     slug: "key-factors-sarjapur",
    //     // image: "https://modernspaaces.com/BlogListImages/BlogFour/Banner.webp",
    //     image: ImgKeyFactorsSarjapur,
    //     label: "Industry",
    //     desc: "Discover why Sarjapur Road in Bangalore stands out as a prime choice for homebuyers seeking an exceptional living experience.",
    //     title: "Key Factors Making Sarjapur Road the Premier Choice for Home...",
    //     date: "Dec,2021",
    //     time: "2 min read",
    // },
];
